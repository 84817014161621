* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: Arial, sans-serif;
}
a {
  text-decoration: none;
  color: #d9d9d9;
}

.navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: #0c6527;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 10;
}

.navbar-brand {
  color: #fff;
  font-size: 1.5rem;
  font-weight: bold;
  text-decoration: none;
}

.navbar-menu {
  display: flex;
  list-style-type: none;
  justify-content: space-evenly;
  width: 500px;
}

.navbar-item {
  margin-left: 1rem;
}

.navbar-link {
  color: #000000;
  text-decoration: none;
  font-size: 1rem;
  transition: color 0.3s ease;
}

.navbar-link:hover {
  color: #000000;
}

.active {
  text-decoration: underline;
  text-underline-position: under;
  font-weight: bold;
  color: #dbba00;
}

.left{
  color: white;
  font-size: 1.4vw;
}

.left span{
  color: red;
}
@media (max-width: 768px) {
  
  .navbar {
    flex-direction: column;
    justify-content: center;
  }

  .navbar-menu {
    margin-top: 0rem;
    font-size: 12px;
    width: 330px;
  }

  .navbar-item {
    margin-left: 0;
    margin-top: 0.5rem;
  }
}
