.btn-detail{
    cursor: pointer;
    padding: 5px;
    margin-top: 5px;
    color: #d9d9d9;
    background-color: rgb(0, 146, 37);
    border: none;
}

.btn-detail:hover{
    background-color: rgb(173, 173, 0);
}