.vehicle-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 2rem;
    margin-top: 2rem;
  }

  .vehicle-card {
    background-color: #ffffff;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
  }

  .vehicle-card:hover {
    transform: translateY(-5px);
  }

  .vehicle-image {
    /* width: 100%; */
    height: 200px;
    object-fit: cover;
  }

  .vehicle-info {
    padding: 1rem;
  }

  .vehicle-name {
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
  }

  .vehicle-description {
    font-size: 0.9rem;
    color: #666;
  }